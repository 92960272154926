module.exports = {
    // 1.首页。。
    HOME:"首页",
    ECOVERSE:"生态",
    SIGN_UP:"注册",
    SIGN_IN:"登录",
    We:"元盾局",//
    protect:"",
    Metaverse:'',//守护元宇宙
    S_1:"<span>安&nbsp;全&nbsp;&nbsp;诚&nbsp;信&nbsp;&nbsp;保&nbsp;险&nbsp;&nbsp;生&nbsp;态&nbsp;&nbsp;保&nbsp;障&nbsp;&nbsp;总&nbsp;局</span> ",//<span>提供元宇宙 NFT 和游戏</span> 数字资产等保障
    JOIN_US:"加入我们",
    INVITE_MEMBER:"邀请加入",
    WHITE_PAPER:"白皮书",
    S_2:"探索 METASHIELD",
    GET_YOUR_TOKENS:"<p>获取您的</p><p class=\"page2TitleP2\">代币</p>",
    RULES:"规则",
    S_3:'用户如果遭受任何损失，可以获得 MetaShield 的代币 $SHLD 作为赔偿。',
    S_4:'用户持有 MetaShield 代币 $SHLD 才能获得赔偿',
    S_5:'用户可以将他们 MetaShield 代币 $SHLD 存储在账户中，以获得钱包及 NFT 等数字资产的保障额度',
    ADD_WALLET:"添加钱包",
    BUY_TOKENS:"购买代币",
    S_6:"<p class=\"page3TitleP2\">METASHIELD 是什么</p>",
    S_7:"MetaShield 是一个通用保护和补偿计划，旨在建立及维护元宇宙中安全秩序。 MetaShield DAO 成立的目的是为了建立和开创元宇宙中链上的资产损失保障体系。它将吸引所有愿意帮助和保护元宇宙安全的人。",
    MORE:"更多",
    S_8:"<p>您将如何抵御这些风险？</p>",
    S_9:"合约漏洞",
    S_10:"当您参与项目的 Defi 池或智能合约被黑客攻击时，如何返还存放在对应资金池中的资产？",
    S_11:"项目跑路",
    S_12:"当您参与的项目中将资产作为贡献支付后，却未能收到承诺的 NFT、代币及代币份额？",
    S_13:"跨链损失",
    S_14:"当您连接不同网络时，由于网络遭受黑客攻击，导致资产丢失？",
    S_15:"私钥丢失",
    S_16:"当您资金池中资产由于黑客攻击、网络钓鱼或任何意外使用而丢失？",
    S_17:"NFT 损失",
    S_18:"当资金池中 NFT 资产由于在 Opensea、Rarible 账户中的网络钓鱼或任何意外使用而丢失？",
    S_19:"游戏资产损失",
    S_20:"当您由于遭受黑客攻击、钓鱼等其他原因导致游戏资产丢失？",
    GUILD_LICENSE:"工会证件",
    S_21:"特工 NFT 的起源",
    S_22:"特工是元宇宙守护者。他们通过链上数据和在线迹象调查审核申诉人提供的证据的充分及真实性。特工目标是确定申诉人损失的原因，无论是恶意攻击、漏洞还是故意作弊等情况。",
    S_23:"获得工作证",
    S_24:"<p>METASHIELD</p><p> 的适用人群</p>",
    S_25:"欢迎所有元宇宙用户加入 MetaShield 社区。只要用户持有 $SHLD，他们就有权在以下情况下通过抵押 $SHLD 提交赔偿申请：\n" +
        // "            the right to submit a claim for compensation by staking $SHLD under following situation:\n" +
        "            <BR/>\n" +
        "            1. 漏洞<BR/>\n" +
        "            2. 欺诈<BR/>\n" +
        "            3. 黑客攻击<BR/>\n" +
        "            4. 项目跑路<BR/>",
    MORE_SCENE:"更多场景......",
    S_26:"<p>我们的</p><p>产品及服务</p>",
    S_27:"元宇宙安全保险",
    S_28:"合约使用保护",
    S_29:"当您参与项目的 Defi 池或智能合约被黑客攻击时，无法请求退款时，我们就您的损失予以赔偿。",
    S_30:"项目跑路保险",
    S_31:"当您参与其他项目已支付资产但未收到代币、代币份额及NFT时，我们就您的损失予以赔偿。",
    S_32:"跨链损失保险",
    S_33:"当您由于黑客攻击，网络错误使用，当跨链桥链接不同网络时资产丢失时，造成的损失我们予以赔偿。",
    S_34:"私钥丢失保险",
    S_35:"当您投保地址遭受黑客攻击、钓鱼或非故意的财务损失时，虽然无法恢复您的私钥，但造成的损失我们予以赔偿。",
    S_36:"NFT 及游戏资产丢失保险",
    S_37:"当您由于遭受黑客攻击、钓鱼等其他原因，投保的NFT及游戏资产丢失时，虽然无法为您复原，但造成的损失我们予以赔偿。",
    S_38:"其他可能发生的损失",
    S_39:"根据 DAO 决定，可以增加其他潜在损失赔偿。",
    S_40:"<p></p><p>路线规划</p>",
    S_41:"了解我们的宏伟计划",
    WELCOME:"欢迎",
    TOU:"加入我们",
    COMMUNITY:"用户社区",
    S_42:"与特工和成员们一起共建<span>元宇宙</span>",
    METASHIELD_COMMUNITY:"METASHIELD 社区",
    S_43:"MetaShield 有五大工会，分别是：Angry Fire、Glory Union、Rage Poseidon、Fairy Forest 和 Ancient Blazer。特工们自豪地加入了各自向往的工会，他们的工作是保护MetaShield中所有用户。",
    COMMUNITY_GOAL:"社区目标",
    S_44:"我们尽一切努力帮助社区获得更美好未来。我们目标是在 MetaShield 社区中共建安全生态系统，处理并解决用户在元宇宙中发生的事件及他们所遇到的情况。",
    COMMUNITY_CAPABILITY:"社区使命",
    S_45:"我们将自由放在社区首要位置。用户可以在 MetaShield 社区中进行许多不同活动，比如在生态中创造自己理想的企业就是很好的场景。",
    S_46:"<p>我们的</p><p>团队</p>",
    S_47:"运营和开发人员",
    S_48:"<p>合作</p><p>伙伴</p> ",
    S_49:"感谢您为 <span>METASHIELD</span> 做出的贡献",
    CONTACT_US:"联系我们",


    // 2. My account界面
    REFRESH:"刷新",
    "My Account":"我的账户",
    "My account":"我的账户",
    ACCOUNT:"账户",
    WALLET:"钱包",
    "SAFETY BOX":"保险箱",
    INVENTORY:"库存",
    "ASSETS ACCOUNT":"资产账户",
    "TOTAL VALUE":"总价值",
    Deposit_USDT:"质押USDT",
    TOKEN:"代币",
    ACTIVE:"流通",
    LOCKED:"锁定",
    INCOME:"收入",
    TYPES:"种类",
    WEEK:"本周",
    TOTAL:"累计",
    License:"证件",
    Headhunter:"人才",
    Direct:"直荐",
    Team:"团队",
    "Network fee 1USDT":"网络GAS费用 1 USDT",
    INSURANCE:"保险",
    "INSURANCE LIMIT":"保险上限",
    "USED AMOUNT":"已用额度",
    USED:"已用",
    LEFT:"剩余",
    "CASH OUT":"提现",
    "TOKEN TYPE":"代币种类",
    "ACTIVE AMOUNT":"可用数量",
    click_to_enter_amount:"点击输入数量",
    "ALL IN":"全部可用",
    Click_to_choose:"点击选择",
    "WALLET LIST":"钱包列表",
    "ADD WALLET":"添加钱包",
    "or":"或者",
    "back":"返回",
    REMOVE:"删除",
    "NO WALLET":"无钱包",
    try:"尝试",
    "ADD A NEW WALLET":"添加新钱包",
    INITIATE_AN_ACCOUNT_TRANSFER:"开始账户转账",
    "ENTER ID":"输入ID",
    "Click to enter":"点击输入",
    "enter amount":"输入数量",
    "HOW TO PAY":"如何支付",
    "please do step 1 first":"请完成第一步",
    Not_find_ID:"未找到ID",
    "ENTER AMOUNT":"输入数量",
    CONFIRM:"确认",
    SEARCHING:"搜索",
    NO_RESULT:"无结果",
    "Click to choose":"点击选择",
    "Max Transfer":"最大可转",
    "please do step":"请完成第",
    "first":"步",




    //Meta Credit界面
    "Meta Credit":"元信用",
    "Meta credit":"元信用",
    CREDIT:"信用",
    "TOTAL QUOTA":"总额度",
    YOUR_CURRENT_CREDIT_QUOTA:"您的目前信用额度",
    USE_THE_CREDIT_LIMIT:"使用最大信用",
    REPAYMENT:"还款",
    CHOOSE_TO_REPAY:"选择还款",
    STATE:"状态",
    Outstanding:"未付",
    AMOUNT:"数量",
    TIME:"时间",
    ALL:"所有",
    PAID_OFF:"已付",
    OUTSTANDING:"未付",
    "REPAY STATE":"还款进度",
    "YOUR CURRENT CREDIT QUOTA":"您当前的信用额度",
    "USE THE CREDIT LIMIT":"使用信用额度",
    "CHOOSE TO REPAY":"选择还款项",
    "MINIMUM PAYMENT":"MINIMUM PAYMENT",
    "FULL":"所有",
    "TYPE":"类型",


    //MARKET界面，
    MARKET:"市场",
    Market:"市场",
    CAST:"库存",
    UPGRADE:"升级",
    "USE CREDIT":"使用信用",
    BUY:"购买",
    EXCHANGE:"兑换",
    "BUY TOKENS":"购买代币",
    "ACCOUNT BALANCE":"账户余额",
    "CREATE EXCHANGE":"兑换",
    TODAY:"今日",
    "CREATE ORDER":"创建订单",
    "WHICH TOKEN DO YOU WANT TO BUY":"您想购买的代币",
    PAYMENT:"支付",
    "HOW MUCH I WANT TO PAY":"想支付的金额",
    "YOU WILL GET":"您将获得",
    "CURRENT QUOTE":"当前报价",
    "NO MORE":"无更多内容",
    "Exchange must be at least 1":"至少兑换1个",
    "Enter amount":"输入数量",
    "CREDIT LIMIT":"信用额度",
    "How to increase credit limit":"如何获得信用额度",
    "YOU PAY":"付款",
    "DETAIL":"明细",
    "CONFIRM ORDER":"提交订单",
    "CONFIRM YOU E-MAIL":"确认您的邮箱地址",
    "VERIFICATION CODE":"验证码",
    "CLICK CONTINUE AND WE WILL SEND A VERIFICATION CODE TO YOUR EMAIL":"点击\"继续\",将向您的电子邮件发送验证码",
    "I agree to the Terms and Comditions":"条款",


    //AGENT HALL页面
    "Agent Hall":"特工大厅",
    "Agent hall":"特工大厅",
    "MY AGENT TEAM":"我的特工团队",
    "MY  AGENT  TEAM":"我的特工团队",
    "PERSONAL CENTER":"个人中心",
    "Personal Center":"个人中心",
    "LICENSE LEVEL":"证件等级",
    "INVITE MEMBER":"邀请成员",
    "SAFEBOX DATA":"保险箱数据",
    "CAREER LEVEL":"工作状态",
    CAREER:"工作",
    EQUIP:"装备",
    "NOT OPEN":"未开放",
    AGENT:"特工",
    LICENSE:"证件",
    "click to choose your license":"点击选择您的证件",
    ITEMS:"道具",
    "AVATAR CARD":"头像卡",
    "CLICK TO CHOOSE YOUR AVATAR CARD":"点击选择您的头像卡",
    AVATAR:"头像",
    STOCKHOUSE:"仓库",
    REWARD:"收益",
    WORK:"工作",
    "Rewards will increase after each countdown":"每次倒计时获得的收益",
    RECEIVE:"获得",
    "THERE IS NO REWARD FOR YOU NOW":"您现在没有工作收益",
    "OPENING SOON":"即将开放",
    "WE WILL OPEN IN THE FUTURE":"即将开放",
    "ITEAMS":"道具",
    "AGENTHALL":"AGENTHALL",

    CONNECTED:"已连接",
    SETTING:"设置",
    "LOG OUT":"登出",

    "Welcome Back":"欢迎回来",
    "INVITE QRCODE":"邀请码",
    "INITIATE AN ACCOUNT TRANSFER":"转账给另外一个账户",
    "OWN":"所有者",
    "PAID OFF":"已结束",
    "e-mail":"邮件地址",
    "teammates":"团队成员",
    "view detail":"查看详情",
    "team":"团队",
    "TEAM":"团队",
    "email":"邮件",
    "lv":"等级",
    "GET REWARDS":"领取奖励",
    "get  rewards":"领取奖励",
    "get rewards":"领取奖励",
    "get  now":"立即领取",
    "CREDIT LIST":"信用记录",

    "SIGN IN":"登录",
    'NOT LOGGED IN':"未登录",
    "sign  in":"登录",
    "LOG IN":"登录",
    "welcome  back":"欢迎回来",
    "agent":"特工",
    "your e-mail":"你的邮箱",
    "WAITING...":"加载中...",
    "sign  up":"注册",
    "password":"密码",
    "forgot password?":"忘记密码？",
    "please enter your E-mail address":"请输入你的邮箱",
    "please enter your password":"请输入你的密码",
    "not an agent yet?":"not an agent yet?",
    "welcome to join metashield":"欢迎加入 metashield",
    "send  code":"发送验证码",
    "verification Code":"验证码",
    "passcode":"设置密码",
    "enter your passcode":"输入密码",
    "reenter to confirm your password":"再次输入密码",
    "please check the format":"格式有误",
    "Invitation code":"邀请码",
    "register":"注册",
    "forgotpassword  up":"重置密码",

    "VALUE_MARKET":"价值",
    "DEPOSIT":"充值",

    "please enter ID":"输入ID",
    "SUBMIT":"提交",
    "SECONDARY CONFIRMATION":"二次确认",
    "Confirm to purchase with AGTC":"确认使用AGTC支付",

    "INVITATION LINK & POST":"邀请链接及海报",
    "selecct_lang":"选择语言",
    "select_post":"选择海报式样",

    "CHOOSE LANGUAGE":"选择语言",
    "CHOOSE POST TYPE":"选择海报样式",
    "PREVIEW & DOWNLOAD":"预览级下载",
    "Long press to save the poster":"长按保存海报",

    "COPY":"复制",
    "NOT EQUIP":"未开放",

    "Deposit Address":"充值地址",
    "Network":"网络",
    de_tis:"此地址不支持充值非同质化代币，请前往NFT页面充值NFT。",
    rechange_ti:"充值USDT/USDC/BUSD",
    "rechagneu_bto":"成功充值的USDC/BUSD将按1:0.999的比例转换为USDT并存入帐户。",
    "rechangeu_msg":"请注意，Metashield平台上仅支持显示的网络。如果您通过其他网络充值，您的资产可能会丢失。此充值地址仅限发送USDT/USDC/BUSD。",

}
































